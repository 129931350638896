<template>
  <HomeComponent/>
  <Projects/>
  <!--  <section id="project">-->
  <!--    <div class="container">-->
  <!--      <h2 class="section-title text-center">Recent Projects</h2>-->

  <!--        <template-->
  <!--          v-for="(project, index) in projectItems"-->
  <!--          :key="`portfolio-item-${index}`"-->
  <!--          class="col-6"-->
  <!--        >-->
  <!--          <PortfolioItem-->
  <!--            :technologies="project.techs"-->
  <!--            :description="project.description"-->
  <!--            :image="project.image"-->
  <!--            :title="project.name"-->
  <!--            class="mb-3"-->
  <!--          />-->
  <!--        </template>-->
  <!--      </div>-->
  <!--  </section>-->
</template>

<script>
import PortfolioItem from '@/components/Portfolio/PortfolioItem';
import Projects from '@/components/Projects';
import HomeComponent from '@/components/Home';
import projects from '@/static/projects';

export default {
  name: 'Home',
  components: {
    Projects,
    HomeComponent,
    PortfolioItem
  },
  setup () {
    const projectItems = projects.map((item) => ({ ...item }));
    return { projectItems };
  }
};
</script>
<style lang="scss">
#project {
  background-image: linear-gradient(135deg, #02aab0, #00cdac);
  -webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  color: #fff;
  margin-bottom: -1px;
  padding: 15rem 0 10rem;

  @media (max-width: 500px) {
    padding-bottom: 3rem;
    padding-top: 7rem;
    clip-path: polygon(0 10vh, 100% 0, 100% 100%, 0 100%);
  }
}

.home-row {
  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
}

.profile-image {
  @media (max-width: 500px) {
    margin-bottom: 3rem;
  }
}
</style>

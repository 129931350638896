import { getCurrentInstance, onMounted } from 'vue';

export function instance () {

  return getCurrentInstance();

}

export function checkIsCompositionApi () {

  const CurrentInstance = instance();

  if (!CurrentInstance) {
    throw new Error('Composable must be run under composition api');
  }
}

export function useMounted (callbacks) {

  checkIsCompositionApi();

  let callbackItems = [];

  if (!Array.isArray(callbacks)) {

    callbackItems.push(callbackItems);

  } else {

    callbackItems = [...callbacks];

  }

  onMounted(() => callbackItems.forEach(async (item) => await item.call()));
}

<template>
  <div class="container container-narrow">
    <section id="home" class="home">
      <div class="home-content">
        <h1 class="home-content__title">
          <span class="home-content__title--name">Hi, I’m Hasan Misbah</span>
          <br/>
          <span class="d-inline-flex me-4">a</span>
          <span ref="typedElement" class="home-content__title--title"></span>
        </h1>
        <p class="home-content--text">I am a curiosity-driven Developer and passionate about bringing both the technical
          and visual aspects of digital products to life.</p>
        <p class="home-content--text">User experience, beautiful pixels, and clean accessible human code matter to me.
          as a follower of Simplicity I agree that less is more.</p>
      </div>
    </section>
  </div>
</template>

<script>
import Typed from 'typed.js';
import { inject, nextTick, ref } from 'vue';
import { useMounted } from '@/composable/composables';

export default {
  name: 'Home',
  setup () {

    const typedElement = ref(null);

    const typedString = [
      '<span style="color: var(--design)">Software engineer</span>',
      '<span style="color: var(--backend)">Fullstack developer</span>',
      '<span style="color: var(--adobe-photoshop)">Web developer</span>'
    ];

    const application = inject('app');

    const initiateTyping = () => {
      nextTick(() => {
        new Typed(typedElement.value, {
          strings: typedString,
          smartBackspace: true,
          typeSpeed: 40,
          stringsElement: null,
          loop: true,
          autoInsertCss: true
        });
      });
    };

    useMounted([initiateTyping]);

    return {
      typedElement,
      application
    };
  }
};
</script>

<style scoped lang="scss">
@import "../stylesheet/style";

</style>

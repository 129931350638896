<template>
<div class="project-item-container">
  <div class="project-image">
    <img class="img img-fluid" :src="image" :alt="name"/>
  </div>
  <div class="project-content">
    <div class="project-header">
      <h2>{{ name }}</h2>
    </div>
    <div class="project-description">
      <p>{{ description }}</p>
    </div>
    <div class="project-footer">
      <ul class="project-tech-list">
        <li
          v-for="(item, index) in techs"
          :key="`tech-item-${index}`"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'ProjectList',
  props: {

    name: {
      type: String,
    },

    image: {
      type: String,
    },

    description: {
      type: String,
    },

    techs: {
      type: Array,
      default: () => []
    },
  },
  setup (props) {

  }
};
</script>

<style scoped lang="scss">
@import "../../stylesheet/_media.scss";
.project {
  &-item-container {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    gap: 2rem;
    background-color: #fff;
    border-radius: 0.375rem;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(0,0,.2,1);
    box-shadow: rgb(0 0 0 / 20%) 0 10px 30px -15px;

    @include xs {
      flex-direction: column;
      align-content: center;
    }

    &:hover{
      box-shadow: rgb(0 0 0 / 20%) 0 8px 20px -15px;
    }
  }

  &-header {
    padding: 15px 0;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include xs {
      align-content: center;
      text-align: center;
    }
  }

  &-description {
    text-align: left;
    font-size: 16px;

    @include xs {
      text-align: left;
    }
  }

  &-image {
    width: 200px;
    min-width: 200px;
    img {
      width: 100%;
      height: auto;
    }
  }

  &-tech-list {

    color: var(--slate);
    font-family: var(--font-sans);
    font-size: var(--fz-xl);
    line-height: 1.3;
    display: flex;
    align-items: flex-end;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      color: var(--slate);
      font-family: var(--font-mono);
      font-size: 15px; //var(--fz-xxs);
      line-height: 1.75;
      margin-right: 15px;
    }

  }
}
</style>

export default [
  {
    name: 'Instant Ordering',
    description: 'Instant Ordering Is an online ordering system for restaurants.',
    image: require('@/assets/images/default-thumbnail.jpg'),
    techs: ['HTML', 'CSS', 'JavaScript', 'Vue.js', 'Element UI']
  },
  {
    name: 'Mail processor',
    description: 'Laravel Based multi gateway supported mail processor. which process mail using multiple email provider Like (sendgrid, mailersend and etc)',
    image: require('@/assets/images/default-thumbnail.jpg'),
    techs: ['PHP', 'Laravel', 'PHPMailer']
  },
  {
    name: 'Rich Panel',
    description: 'Richpanel is an Ecommerce Customer Support and Experience Platform that helps data-first enterprises to deliver richer, more personalized experiences.',
    image: require('@/assets/images/project/Richpanel_logo_colored.svg'),
    techs: ['HTML', 'CSS', 'JS', 'VUEJS', 'Element UI']
  },

  {
    name: 'OTO',
    description:
      'OTO is a network of hundreds of electric scooters and Bicycle that you can pick up and drop off. provide a smart, convenient, easy and reliable transportation alternative to make cleaner, healthier planet.',
    image: require('@/assets/images/project/oto.png'),
    techs: ['HTML', 'CSS', 'JS', 'VUEJS', 'TailwindCSS']
  },

  {
    name: 'Our Women in politics',
    description: 'The Our Women In Politics organization strives to demonstrate the impact of women in political leadership, as well as bring much-needed attention and support to the many voices of women who want to be a part of global change.',
    image: require('@/assets/images/project/women-logo.png'),
    techs: ['HTML', 'CSS', 'JS', 'jQuery']
  },

  {
    name: 'An Skillbar (jQuery Plugin)',
    description:
      'The an-skill-bar plugin lets you create animated skill/experience bars which can be used for online resumes. \n The plugin creates horizontal progress bars to represents your skills/experiences in percentages.',
    image: require('@/assets/images/project/animated-skills-bar.jpg'),
    techs: ['javascript', 'jQuery', 'CSS']
  }
];

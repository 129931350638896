<script>
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/stylesheet/style.scss';
import NavBar from '@/Layout/Shared/NavBar';
import { defineComponent, h, provide, resolveComponent } from 'vue';
import Footer from '@/Layout/Shared/Footer';
import projects from '@/static/projects';

export default defineComponent({

  name: 'Main',

  setup () {
    provide('projects', projects);
    return () => [
      h('header', {}, h(NavBar)),
      h(resolveComponent('RouterView')),
      h(Footer)
    ];
  }
});
</script>

<template>
  <div class="project-container">
    <div class="image">
      <img :alt="title" class="img-fluid" :src="image">
    </div>
    <div class="content">
      <div class="header">
        <div class="inner">
          <h2>{{ title }}</h2>
        </div>
        <div class="project-content">
          <p>{{ description }}</p>
          <p class="mt-2" v-if="technologies.length"><strong>Technologies: </strong>
            <span v-for="(technology, index) in technologies" :key="`portfolio-item-${index}`">{{ technology }}, </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PortfolioItem',
  props: {

    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    },

    image: {
      type: String,
      required: true
    },

    technologies: {
      type: Array,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
.project-container {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background: #fff;
  border-radius: .375rem;
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

  @media (max-width: 500px) {
    flex-direction: column;
    row-gap: 20px;
  }

  .image {
    width: 5rem;
    flex-shrink: 0;
    color: #2d3748;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .content {
    margin-top: 0;
    margin-inline-end: 0;
    margin-bottom: 0;
    margin-inline-start: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #2D3748;

    @media (max-width: 500px) {
      margin-inline-start: 0;
    }

    .header {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .inner {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        display: flex;
        width: 100%;

        @media (max-width: 500px) {
          display: block;
        }

        h2 {
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 1.2;

          @media (max-width: 500px) {
            font-size: 1.5rem;
            text-align: center;
          }
        }
      }

      .project-content {
        font-weight: 500;

        p {
          margin-top: .75rem;
          margin-inline-end: 0;
          margin-bottom: 0;
          margin-inline-start: 0;
          font-size: 16px;
        }
      }
    }
  }
}
</style>

<template>
  <footer id="footer">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 text-center">
          <h2 class="footer-heading">
            <RouterLink class="logo" to="/">Hasan Misbah</RouterLink>
          </h2>
          <p class="menu">
            <a href="#">Home</a>
            <a href="#">About</a>
            <a href="#">Project</a>
            <a href="#">Blog</a>
            <a href="#">Contact</a>
          </p>
          <ul class="ftco-footer-social p-0">
            <li
              class="ftco-animate">
              <a
                href="https://linkedin.com/in/hasanmisbah"
                target="_blank"
                title="Linked in"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li
              class="ftco-animate">
              <a
                href="https://github.com/hasanmisbah"
                target="_blank"
                title="Github"
              >
                <i class="fab fa-github"></i>
              </a>
            </li>
            <li
              class="ftco-animate">
              <a
                href="https://twitter.com/hasanmisbah01"
                target="_blank"
                title="Twitter"
              >
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li
              class="ftco-animate">
              <a
                href="https://facebook.com/hasanmisbah01"
                target="_blank"
                title="Facebook"
              >
                <i class="fab fa-facebook"></i>
              </a>
            </li>
            <li
              class="ftco-animate">
              <a
                href="https://instagram.com/hasanmisbah"
                target="_blank"
                title="Instagram">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12 text-center">
          <p class="copyright"> Copyright © 2022 All rights reserved by Hasan Misbah</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer'
});
</script>

<style lang="scss" scoped>
@import "../../stylesheet/style.scss";
#footer {
  @include generateWave(#000000);
  background: #333;
  padding: 3em 0;
  display: block;

  .footer-heading {
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    margin: 30px 0;
  }

  a {
    color: #00cdac;
    text-decoration: none;
  }

  .menu {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    row-gap: 10px;

    a {
      color: rgba(255, 255, 255, 0.6);
      margin: 0 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  li {
    list-style: none;
    margin: 0 10px 0 0;
    display: inline-block;

    a {
      height: 40px;
      width: 40px;
      display: block;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      position: relative;
      border: 1px solid #00cdac;

      i {
        position: absolute;
        font-size: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .copyright {
    color: rgba(255, 255, 255, 0.3);
  }
}
</style>

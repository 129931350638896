<template>
  <section class="section container-fluid project-container">
    <h2 class="section-title text-center">Projects</h2>
    <p class="section-subtitle text-center">Some project i've worked</p>
    <div class="container container-narrow mt-5">
      <div class="project-grid">
        <ProjectList
          v-for="(project, index) in projects"
          :key="`project-item-${index}`"
          v-bind="project"
        />
        <!--        <ProjectsItem-->
        <!--          v-for="(project, index) in projects"-->
        <!--          :key="`project-item-${index}`"-->
        <!--          v-bind="project"-->
        <!--        />-->
      </div>
    </div>
  </section>
</template>

<script>
import ProjectsItem from '@/components/projects/ProjectsItem';
import { inject } from 'vue';
import ProjectList from '@/components/projects/ProjectList';

export default {
  name: 'Projects',
  components: {
    ProjectList,
    ProjectsItem
  },
  setup () {
    const projects = inject('projects');
    return {
      projects
    };
  }
};
</script>

<style lang="scss" scoped>
.project {
  &-container {
    background-color: #f6f8fa;
  }

  &-grid {
    display: grid;
    gap: 2rem;
  }
}

//.project {
//
//  &-container {
//    background-color: #f6f8fa;
//
//    .section-title {
//      margin: 0;
//    }
//
//  }
//
//  &-grid {
//    display: grid;
//    grid-auto-rows: 1fr;
//    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//    gap: 1rem;
//  }
//
//}
</style>

<script>
import Main from '@/Layout/Main';
import { h, reactive, provide, defineComponent } from 'vue';

export default defineComponent({

  setup () {

    const app = reactive({
      loading: false,
      setLoading: (v) => app.loading = v,
    });

    provide('app', app);

    return () => h(Main);
  }
});
</script>

<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container container-narrow">
      <RouterLink class="navbar-brand fw-bold" to="/">Hasan Misbah</RouterLink>
      <button
        :class="state.showNavbar ? 'collapsed' : ''"
        class="navbar-toggler"
        type="button"
        @click="updateNavigationState"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <Transition name="slide-fade">
        <div v-if="state.isActiveNavbar" id="navbarNav" class="collapse navbar-collapse show">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <RouterLink
                class="nav-link active" to="/"
                @click="updateNavigationState"
              >
                Home
              </RouterLink>
            </li>
            <li class="nav-item">
              <RouterLink
                class="nav-link"
                to="/about"
                @click="updateNavigationState"
              >
                About
              </RouterLink>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  </nav>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue';

import { useWindowSize } from '@vueuse/core';

export default defineComponent({
  name: 'NavBar',
  setup () {
    const { width } = useWindowSize();

    const state = reactive({
      showNavbar: false,
      isActiveNavbar: computed(() => {
        return width.value >= 500 ? true : state.showNavbar;
      })
    });

    const updateNavigationState = () => {
      if (width.value > 501) {
        return;
      }

      state.showNavbar = !state.showNavbar;
    };

    return {
      state,
      updateNavigationState
    };
  }
});
</script>

<style lang="scss" scoped>

.slide-fade-leave-active, .slide-fade-enter-active {
  transition: all .2s ease;
}

.slide-fade-leave-to, .slide-fade-enter {
  transform: translateY(-50px);
  opacity: 0;
}
</style>

<template>
  <div class="project-item">
    <div class="image">
      <img :src="image" :alt="name">
    </div>
    <div class="project-item-top">
      <div class="folder">
        <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor"
             stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder"><title>
          Folder</title>
          <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
        </svg>
      </div>
      <div class="project-links"><a
        href="https://medium.com/stories-from-upstatement/integrating-algolia-search-with-wordpress-multisite-e2dea3ed449c"
        aria-label="External Link" class="external" target="_blank" rel="noopener noreferrer">
        <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor"
             stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link">
          <title>External Link</title>
          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
          <polyline points="15 3 21 3 21 9"></polyline>
          <line x1="10" y1="14" x2="21" y2="3"></line>
        </svg>
      </a></div>
    </div>
    <div class="project-item-inner">
      <div class="project-item-description">
        <h3 class="project-item-title">
          <a
            href="https://medium.com/stories-from-upstatement/integrating-algolia-search-with-wordpress-multisite-e2dea3ed449c"
            target="_blank" rel="noopener noreferrer">{{ name }}</a>
        </h3>
        <p> {{ description }} </p>
      </div>
      <div class="project-item-footer d-flex flex-column">
        <ul class="project-tech-list">
          <li
            v-for="(item, index) in techs"
            :key="`tech-item-${index}`"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsItem',

  props: {

    name: {
      type: String,
    },

    image: {
      type: String,
    },

    description: {
      type: String,
    },

    techs: {
      type: Array,
      default: () => []
    },
  }
};
</script>

<style lang="scss" scoped>
.project {

  &-item {
    background-color: #fff;
    color: var(--slate);
    font-family: var(--font-sans);
    font-size: var(--fz-xl);
    line-height: 1.3;
    display: block;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    box-shadow: rgb(0 0 0 / 20%) 0 10px 30px -15px;

    &:hover{
      box-shadow: rgb(0 0 0 / 20%) 0 8px 20px -15px;
    }
  }

  &-item {

    .image {
      height: 150px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 10px;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    &-inner {
      color: var(--navy);
      font-family: var(--font-sans);
      font-size: var(--fz-xl);
      line-height: 1.3;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      border-radius: var(--border-radius);
      transition: var(--transition);
      padding: 1rem 1.75rem 2rem;

    }

    &-top {
      color: var(--navy);
      font-family: var(--font-sans);
      font-size: var(--fz-xl);
      line-height: 1.3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      width: 100%;
      padding: 0 1rem;

      .folder {
        font-family: var(--font-sans);
        font-size: var(--fz-xl);
        line-height: 1.3;
        color: var(--navy);

        svg {
          font-family: var(--font-sans);
          font-size: var(--fz-xl);
          line-height: 1.3;
          color: var(--navy);
          stroke: currentcolor;
          stroke-width: 1;
          stroke-linecap: round;
          stroke-linejoin: round;
          max-width: 100%;
          vertical-align: middle;
          fill: none;
          width: 40px;
          height: 40px;
        }
      }

      .project-links {
        -webkit-font-smoothing: antialiased;
        font-family: var(--font-sans);
        font-size: var(--fz-xl);
        line-height: 1.3;
        display: flex;
        align-items: center;
        margin-right: -10px;
        color: var(--navy);

        .external {
          --ham-after-active: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
          font-family: var(--font-sans);
          font-size: var(--fz-xl);
          line-height: 1.3;
          text-decoration: none;
          text-decoration-skip-ink: auto;
          color: inherit;
          transition: var(--transition);
          position: relative;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 7px;
        }

        svg {
          font-family: var(--font-sans);
          font-size: var(--fz-xl);
          line-height: 1.3;
          text-decoration-skip-ink: auto;
          color: inherit;
          stroke: currentcolor;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          box-sizing: inherit;
          max-width: 100%;
          vertical-align: middle;
          fill: none;
          width: 22px;
          height: 22px;
          margin-top: -4px;
        }
      }
    }

    &-title {
      font-family: var(--font-sans);
      list-style: none;
      font-weight: 600;
      line-height: 1.1;
      margin: 0 0 10px;
      color: var(--navy);
      font-size: var(--fz-xxl);

      a {
        font-family: var(--font-sans);
        font-weight: 600;
        line-height: 1.1;
        font-size: var(--fz-xxl);
        display: inline-block;
        text-decoration: none;
        text-decoration-skip-ink: auto;
        color: inherit;
        transition: var(--transition);
        z-index: 1;
        position: static;
      }
    }

    &-description {
      font-family: var(--font-sans);
      line-height: 1.3;
      color: var(--navy);
      font-size: 14px;

      p {
        font-family: var(--font-sans);
        line-height: 1.3;
        color: var(--navy);
      }
    }

    &-footer {

    }

  }

  &-tech-list {

    color: var(--slate);
    font-family: var(--font-sans);
    font-size: var(--fz-xl);
    line-height: 1.3;
    display: flex;
    align-items: flex-end;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      color: var(--slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;
      margin-right: 15px;
    }

  }
}
</style>
